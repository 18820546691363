<template>
	<header>
      <div class="header__area">
         <div class="header__top d-none d-sm-block text-white">
            <div class="container px-0 px-md-3">
               <div class="header__welcome d-flex justify-content-center justify-content-xl-start">
                  <ul>
                     <li><router-link to="/historia">História</router-link></li>
                     <li><router-link to="/categorias">Produtos</router-link></li>
                     <li><router-link to="/noticias">Notícias</router-link></li>
                     <li><router-link to="/downloads">Downloads</router-link></li>
                     <li><a :href="urlCCM +'/trabalheConosco'" target="_blank">Trabalhe conosco</a></li>
                  </ul>
               </div>
            </div>
         </div>

         <div class="header__info py-xxl-3">
            <div class="container">
               <div class="row align-items-center">
                  <!-- Logo -->
                  <div class="col-lg-2 mb-2 mb-md-4 mb-lg-0">
                     <div class="header__info-left d-flex justify-content-center justify-content-lg-end align-items-center">
                        <div class="logo">
                           <router-link to="/">
                              <img src="@/assets/cliente/img/logo/logo-sm.png" alt="logo">
                           </router-link>
                        </div>
                     </div>
                  </div>

                  <!-- Pesquisa -->
                  <div class="col-lg-7 col-md-9 d-none d-md-block">
                     <div class="header__info-right">
                        <div class="header__search w-100">
                           <form action="javascript:;">
                              <div class="header__search-box">
                                 <input type="text" :placeholder="'Tipo, nome ou código d'+ (pesquisa.tipoPesquisa == 'Peças' ? 'a peça' : 'o produto')" v-model="pesquisa.valor" @keyup.enter="pesquisar">
                                 <button @click="pesquisar"><i class="far fa-search d-inline d-sm-none"></i><span class="d-none d-sm-inline">Pesquisar</span></button>
                              </div>
                              <div class="header__search-cat">
                                 <v-select title="Desktop" :searchable="false" class="border-0" :options="['Geral', 'Peças']" v-model="pesquisa.tipoPesquisa" placeholder="Classe" />
                                 <select title="Mobile" class="form-control border-0" v-model="pesquisa.tipoPesquisa">
                                    <option v-for="(option, index) in ['Geral', 'Peças']" :key="index" :value="option">{{ option }}</option>
                                 </select>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-3 d-flex d-md-block justify-content-between justify-content-md-start">
                     <button type="button" class="side-menu-btn offcanvas-toggle-btn ml-25 d-block d-md-none" @click="abrirMenu">
                        <i class="far fa-bars font-20 mb-2"></i>
                     </button>
                     <a :href="urlCCM +'/sejaRevendedor'" class="btn btn-revendedor mb-2 mb-md-0" target="_blank">SEJA UM REVENDEDOR</a>
                  </div>
               </div>

               <!-- Categorias -->
               <div class="row align-items-center mt-1 d-none d-xxl-flex" v-if="clienteData.categorias != null && clienteData.categorias.length > 0">
                  <div class="col-lg-2"></div>
                  <div class="col-xl-7 col-lg-9">
                     <div class="header__action main-menu">
                        <ul class="d-flex justify-content-center color-theme">
                           <categoria v-for="(categoria, index) in clienteData.categorias" :key="index" :categoria="categoria" :page="'Header'" :length="clienteData.categorias.length" />
                        </ul>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <router-link to="/categorias" role="button" class="btn btn-category limitador">
                        <i class="far fa-bars font-12 me-1"></i> Todas categorias
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import categoria from '@/components/home/Categoria.vue'

export default {
	name: 'Header',
   data : function () {
      return {
         pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
      }
   },
   computed: {
		... mapState({
			clienteData: state => state.clienteData,
			contato: state => state.source.contato,
         urlCCM: state => state.urlCCM
		})
	},
   components: {
      categoria
   },
   methods: {
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', JSON.parse(JSON.stringify(this.pesquisa)))
         
         setTimeout(() => {
            this.pesquisa = {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
         }, 250);
      },
      abrirMenu : function () {
         $(".offcanvas__area").addClass("opened");
         $(".body-overlay").addClass("opened");
      }
   }
}

</script>

<style scoped>

.btn-category,
.btn-revendedor {
   box-shadow: none !important;
   width: max-content;
   max-width: 100%;
   text-transform: capitalize !important;
   font-weight: 500 !important;
   border-radius: 3px !important;
}

.btn-category {
   background: #fff !important;
   color: var(--color-theme) !important;
   font-size: 13px !important;
   box-shadow: none !important;
   padding: 3px 12px !important;
   text-transform: capitalize !important;
   font-weight: 500 !important;
}

.btn-revendedor {
   color: #000 !important;
   font-size: 12px !important;
   padding: 12px 14px !important;
   line-height: 1 !important;
   background: #ecde1b;
}

.header__info-right {
   padding-top: 6px;
   padding-bottom: 6px;
}

</style>