<template>
	<div id="app" class="cliente">
		<div id="loading" v-if="isCarregando == true">
			<div id="loading-center">
				<div id="loading-center-absolute">
					<img id="object" src="@/assets/cliente/img/logo/logo-lg.png" alt="logo">
				</div>
			</div>
		</div>

		<a href="javascript:;" class="back-to-top bg-theme" @click="backToTop">
			<i class='fas fa-arrow-up'></i>
		</a>
		
		<Header v-show="$route.name != 'Produto_Divulgação'" />

		<div class="offcanvas__area">
			<div class="offcanvas__wrapper">
				<div class="offcanvas__close">
					<button class="offcanvas__close-btn mt-sm-2" id="offcanvas__close-btn" @click="fecharMenu">
						<i class="fal fa-times"></i>
					</button>
				</div>
				<div class="offcanvas__content">
					<div class="offcanvas__logo mb-15">
						<a href="javascript:;">
							<img src="@/assets/cliente/img/logo/logo-sm.png" alt="logo">
						</a>
					</div>
					<div class="offcanvas__search mb-25">
						<form action="javascript:;">
							<input type="text" placeholder="Pesquisar..." v-model="pesquisa.valor" @keyup.enter="pesquisar">
							<button @click="pesquisar"><i class="far fa-search"></i></button>
						</form>
					</div>
					<div class="mobile-menu fix mean-container">
						<div class="mean-bar">
							<nav class="mean-nav">
								<ul>
									<li><router-link to="/historia" class="weight-400">História</router-link></li>
                           <li><router-link to="/categorias" class="weight-400">Produtos</router-link></li>
                           <li><router-link to="/noticias" class="weight-400">Notícias</router-link></li>
                           <li><router-link to="/downloads" class="weight-400">Downloads</router-link></li>
									<li><a :href="urlCCM +'/trabalheConosco'" class="weight-400" target="_blank">Trabalhe conosco</a></li>
                           <li><router-link to="/encontreRevenda" class="weight-400">Encontre uma revenda</router-link></li>
                           <li><router-link to="/assistenciaTecnica" class="weight-400">Assistência Técnica</router-link></li>
									<li><a :href="urlCCM +'/sejaRevendedor'" class="weight-400" target="_blank">Seja um revendedor</a></li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="offcanvas__action"></div>
				</div>
			</div>
		</div>
		<div class="body-overlay" @click="fecharMenu"></div>

		<router-view />

		<Footer v-show="$route.name != 'Produto_Divulgação'" />
   </div>
</template>

<script>

import $ from 'jquery'
import Vue from 'vue'
import { mapState } from 'vuex'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
	name: 'App',
	data: function () {
		return {
			pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
		}
	},
	computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
         isCarregando: state => state.isCarregando,
			urlCCM: state => state.urlCCM
		})
	},
	components: {
		Footer, Header
	},
	methods: {
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', this.pesquisa)
      },
		fecharMenu : function () {
			$(".offcanvas__area").removeClass("opened");
			$(".body-overlay").removeClass("opened");
		},
		backToTop : function () {
			window.scrollTo(0, 0)
		}
	},
	mounted() {
		this.$store.dispatch('getData')

		Vue.nextTick(function() {
         $(document).scroll(function() {
            if ($(document).scrollTop() > 300) {
               $('.back-to-top').fadeIn();
            } else {
               $('.back-to-top').fadeOut();
            }
         });
		}.bind(this));
	}
}

</script>