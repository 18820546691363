<template>
   <a v-if="page == 'Header'" class="col-6 p-0 limitador" href="javascript:;" @click="pesquisar">
      <span class="font-14"><i class="fas fa-long-arrow-alt-right color-theme font-10 me-1"></i> {{ subcategoria.nome }}</span>
   </a>

   <div v-else-if="page == 'Categorias'" class="card">
      <div class="card-body fix">
         <div class="blog__thumb fix">
            <a href="javascript:;">
               <img :src="subcategoria.foto == null ? '' : subcategoria.foto" class="cursor-pointer" alt="subcategoria" @error="imageError" @click="pesquisar">
            </a>
         </div>
         <div class="blog__content pt-12 px-0 pb-0">
            <h3 class="limitador text-center font-15 mb-0"><a href="javascript:;" class="cursor-pointer" @click="pesquisar">{{ subcategoria.nome }}</a></h3>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Subcategoria',
   props: ['subcategoria', 'page', 'categoria'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', {
            'categoria': (this.categoria == null ? this.subcategoria.nomeCategoria : this.categoria), 
            'subcategoria': this.subcategoria.nome, 
            'tipoPesquisa': 'Subcategoria', 
            'valor': this.subcategoria.nome
         })
      }
   }
}

</script>

<style scoped>

.submenu li a.position-relative span {
   position: absolute;
   bottom: 8px;
   left: 8px;
   right: 8px;
	color: #fff;
	text-shadow: 1px 1px 1px #000;
   font-size: 16px !important;
}

li:not(.not-dropdown) a {
   font-weight: 400 !important;
   text-transform: capitalize !important;
   color: rgb(81, 90, 108) !important;
}

.submenu i {
   position: unset !important;
   top: unset !important;
   right: unset !important;
   transform: unset !important;
}

* {
   cursor: auto;
}

a:not(.position-relative) span {
   cursor: pointer !important;
}

</style>