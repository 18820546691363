import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import router from '@/router'
import source from '../../public/source.json'
import imgError from '@/assets/cliente/img/error.png'

Vue.use(Vuex)

const store = {
   state: {
      // urlRest: 'http://192.168.5.151:8080/CCM-0.0.1/',
      urlRest: 'https://novosite.ccmdobrasil.com.br/CCM/',
      urlCCM: 'https://novosite.ccmdobrasil.com.br/',
      source: source,
      isCarregando: true,
      clienteData: {'lancamentos': [], 'noticias': [], 'categorias': [], 'marcas': [], 'depoimentos': [], 'banners': []},
      carrinho: {'itens': [], 'valorTotal': 0, 'toggle': false},
      pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': null, 'historico': [], 'resultado': [], 'filtros': [], 'relacionados': []},
      pesquisaDesabilitado: false,
      routerHistory: [],
      firstRoute: null,
      timerBlog: 100
   },
   mutations: {
      saveHistory : (state, route) => {
         if (state.routerHistory.length == 0) {
            state.firstRoute = route;
         }

         state.routerHistory.push(route)
         window.scrollTo(0, 0)
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      },
		saveData : (state, data) => {
         state.clienteData = data;

         if (data.text != null) {
            state.source = data.text;
         }

         if (data.noticias.length > 0) {
            if (data.noticias.length == 3) {
               state.clienteData.noticias.push(data.noticias[0])
               
            } else if (data.noticias.length == 2) {
               state.clienteData.noticias.push(...data.noticias)
               
            } else if (data.noticias.length == 1) {
               state.clienteData.noticias.push(data.noticias[0])
               state.clienteData.noticias.push(data.noticias[0])
               state.clienteData.noticias.push(data.noticias[0])
            }
            
            setInterval(() => {
               if (state.timerBlog == 0) {
                  state.timerBlog = 100;
                  
                  let item  = state.clienteData.noticias[0];
                  state.clienteData.noticias.splice(0, 1)
                  state.clienteData.noticias.push(item)
               }
   
               state.timerBlog--;
            }, 100)
         }
      },
      setPesquisa : (state, pesquisa) => {
         state.pesquisa = {
            'categoria': pesquisa.categoria,
            'subcategoria': pesquisa.subcategoria,
            'tipoPesquisa': pesquisa.tipoPesquisa,
            'valor': pesquisa.valor,
            'historico': [],
            'resultado': [],
            'filtros': [],
            'relacionados': []
         }

         store.mutations.pesquisar(state, pesquisa)
      },
      pesquisar : (state, pesquisa) => {
         let jaPesquisado = state.pesquisa.historico.map(pesquisa => pesquisa.valor.toUpperCase()).includes(String(pesquisa.valor).trim().toUpperCase());
         
         if (pesquisa.tipoPesquisa != 'Desabilitados') {
            if (jaPesquisado) {
               return
            } else if (pesquisa.valor != null && String(pesquisa.valor).trim().length > 0) {
               state.pesquisa.historico.push(JSON.parse(JSON.stringify(pesquisa)))
            }
         }
         
         state.isCarregando = true;
         localStorage.ccmPesquisa = JSON.stringify(state.pesquisa.historico)
         
         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'site/searchProdutos',
            headers: {
					'Content-Type': 'application/json'
            },
				data: state.pesquisa.historico,
            params: {
               desabilitados: state.pesquisaDesabilitado
            }
         }).then(response => {
            state.pesquisa.resultado = response.data.resultado;
            state.pesquisa.filtros = response.data.filtros;
            state.pesquisa.relacionados = response.data.relacionados;
            
         }).catch(function (error) {
            if (error.response != undefined) {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            router.push('/produtos').catch(function(){})
            state.isCarregando = false;

            $(".offcanvas__area").removeClass("opened");
            $(".body-overlay").removeClass("opened");
         });
      },
      deletePesquisa : (state, index) => {
         state.pesquisa.historico.splice(index, 1)
         store.mutations.pesquisar(state, {
            'categoria': state.pesquisa.categoria,
            'subcategoria': state.pesquisa.subcategoria,
            'tipoPesquisa': state.pesquisa.tipoPesquisa,
            'valor': null
         })
      },
      resgatarPesquisa : (state, pesquisa) => {
         state.pesquisa.historico = pesquisa;
      },
      togglePesquisarDesabilitados : state => {
         state.pesquisaDesabilitado = !state.pesquisaDesabilitado
      }
   },
   actions: {
      /* eslint-disable-next-line no-unused-vars */
      imageError : ({commit}, e) => {
         e.target.src = imgError;
      },
      /* eslint-disable-next-line no-unused-vars */
      buscarCEP : ({commit}, cep) => {
         return new Promise((resolve, reject) => {
            Vue.prototype.$axios({
               method: 'get',
               url: 'https://viacep.com.br/ws/'+ cep +'/json/'

            }).then(response => {
               if (response.data.erro) {
                  reject(null)
               } else {
                  resolve(response.data)
               }
            }).catch(function () {
               reject(null)
            });
         })
      },
      getData : context => {
         /* eslint-disable-next-line no-unused-vars */
         return new Promise((resolve, reject) => {
            context.commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'get',
               url: store.state.urlRest +'site/getDados'

            }).then(response => {
               context.commit('saveData', response.data)
               
            }).catch(function (error) {
               if (error.response != undefined) {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               context.commit('alternarTelaCarregamento', false)
               resolve()
            });
         })
      },
      saveHistory : ({commit}, route) => {
         commit('saveHistory', route)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      setPesquisa : ({commit}, pesquisa) => {
         commit('setPesquisa', pesquisa)
      },
      pesquisar : ({commit}, pesquisa) => {
         commit('pesquisar', pesquisa)
      },
      deletePesquisa : ({commit}, index) => {
         commit('deletePesquisa', index)
      },
      resgatarPesquisa : ({commit}, pesquisa) => {
         commit('resgatarPesquisa', pesquisa)
      },
      togglePesquisarDesabilitados : context => context.commit('togglePesquisarDesabilitados')
   },
   modules: {
   }
}

export default new Vuex.Store(store);